import React from 'react';

const Lost = () => {
    return (
        <div>
            <h2>LOST</h2>
            
        </div>
    );
};

export default Lost;